<template lang="pug">
li.tree-list__group--sentinelle(:id='`tree-item-${item.id}`')
  .tree-list__item--sentinelle(:class='{ "has-childs": hasChild }')
    slot(name='label', v-bind:item='item')
      | {{ item.name }}

  template(v-if='hasChild')
    ul
      tree-item(:item='child', v-for='child in item.child', :key='child.id')
        template(v-slot:label='slotProps')
          slot(name='label', v-bind:item='child')
</template>

<script>
export default {
  components: {
    TreeItem: () => import('./TreeItem'),
  },
  props: {
    item: {
      type: Object,
      require: true,
      default: null,
    },
    openAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    hasChild() {
      return this.item.child && this.item.child.length
    },
  },
  methods: {},
}
</script>

<style lang="scss">
.tree-list__group--sentinelle {
  display: block;
  background: #ffffff;

  .tree-list__item--sentinelle {
    padding: 0.25rem 0;
  }

  // parent group
  &.is-selected > .tree-list__item--sentinelle {
    background-color: #e0e0e0;
  }
  // group's childs
  &.is-selected .tree-list__group--sentinelle {
    background-color: #f5f5f5;
  }

  ul {
    padding: 0;
    .tree-list__group--sentinelle > .tree-list__item--sentinelle {
      padding-left: 24px;
      background-clip: border-box;
    }
  }
}
.tree-list__item--sentinelle {
  &:hover,
  &:focus {
    background-color: #eeeeee;
  }
  &:active {
    background-color: #e0e0e0;
  }
}
</style>
